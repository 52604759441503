.navbar-toggle {
    position: fixed;
    top: 1em;
    left: 1em;
    z-index: 101;
    background-color: transparent;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 2em;
  }

  .navbar-container {
    background:#172333;
    /* border-radius: 16px; */
     box-shadow: 0px 4px 4px 0px #00000040;

   /* backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(0, 0, 0, 0.3); */
    position: fixed;
    top: 0;
  }
  
  .overlay-navbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.5) 0%, rgba(255, 255, 255, 0.5) 40%, rgba(255, 255, 255, 0.5) 60%, rgba(0, 0, 0, 0.5) 100%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    animation: ease-in-out 1s;
    width: 100vw;
    backdrop-filter: blur(5px); 
}  
  .nav-btn {
    margin: 5px;
    background: transparent;
    border: none;
    padding: 10px;
    cursor: pointer;
    border: none;
    font-size: 2vh;
    font-weight: 500;
    font-family: 'Akshar', sans-serif;
    font-family: 'Megrim', cursive;
    font-family: 'Orbitron', sans-serif;
  }
  .nav-btn:hover{
    margin: 5px;
    background: transparent;
    border: none;
    padding: 10px;
    cursor: pointer;
    border: none;
    font-size: 2vh;
    font-weight: 500;
    font-family: 'Akshar', sans-serif;
    font-family: 'Megrim', cursive;
    font-family: 'Orbitron', sans-serif;
    background-color: #0E8CE8;
    color: white;
    border-radius: 10px;
  }
  .logout-btn{
    position: fixed;
    top: 50px;
    right: 10px;
    padding: 10px 20px;
    text-decoration: underline;
    font-size: large;
    font-family: 'Akshar', sans-serif;
    font-family: 'Megrim', cursive;
    font-family: 'Orbitron', sans-serif;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: transparent;
    transition: background-color 0.3s;
  }
  .logout-btn:hover {
    background-color: #555;
    color: white;
  }
  
  .logo{
    position: fixed;
    top: 1.1em;
    left: 4em;
    z-index: 101;
    background-color: transparent;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 2em;
    font-weight: 500;
    text-decoration: underline;
    text-decoration-color: green;
    transition: ease-in-out(3s);
    img{
      width: 200px !important; 
      
    }
  }
  
  .home-btn{
    position: fixed;
    top: 2vh;
    padding: 10px 20px;
    text-decoration: underline;
    font-size:xx-large;
    font-family: 'Akshar', sans-serif;
    font-family: 'Megrim', cursive;
    font-family: 'Orbitron', sans-serif;
    font-weight: 500;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: transparent;
    transition: background-color 0.3s;
  }
  .home-btn:hover{
    background-color: #555;
  }
  .login-register-btn {
    position: fixed;
    top: 10px;
    right: 10px;
    padding: 10px 20px;
    /* background-color: #333;   */
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
   
  }
  
  .login-register-btn:hover {
    background-color: #555;
  }
  
  @media (min-width: 320px) and (max-width: 375px) {
    .home-btn{
      top:10vh;
    }
  }
  
  @media (min-width: 325px) and (max-width: 500px) {
    .logo{
      left:3em;
    }
  }