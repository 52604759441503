@import url('https://fonts.googleapis.com/css2?family=Akshar:wght@300;400;500;600;700&family=Megrim&family=Orbitron:wght@400;500;600;700;800;900&display=swap');

html {
    overflow-y: auto;
    overflow-x: hidden;
    scroll-behavior: smooth;
    scroll-snap-type: y mandatory;
}

body {
    font-family: 'Akshar', sans-serif;
    font-family: 'Megrim', cursive;
    font-family: 'Orbitron', sans-serif;
}

.register {
    display: flex;
    height: 100vh;
    width: 100vw;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #142335;
}

.register h1 {
    color: white;
    font-weight: 500;
    font-size: 3rem;
}

.register__info {
    display: flex;
    flex-direction: column;
    gap: 1vh;
    background-color: #E4E4E4;
    width: 60%;
    height: 60%;
    justify-content: center;
    align-items: center;
    border-radius: 2rem;
}

.register__form {
    display: flex;
    flex-direction: column;
    gap: 5vh;
    height: 80%;
    width: 60%;
    font-family: 'Obitron', sans-serif;
    /* justify-content: center; */
}

.inp {
    height: 80%;
    width: 100%;
}

.lbl {
    gap: 2em;
}

.checkbutton {
    background-color: #ECEFF2;
    color: #262626;
    width: 60%;
    align-self: center;
    height: 15%;
    border-style: none;
    font-family: 'Obitron', sans-serif;
    font-size: x-large;
    cursor: pointer;
}