@import url('https://fonts.googleapis.com/css2?family=Akshar:wght@300;400;500;600;700&family=Megrim&family=Orbitron:wght@400;500;600;700;800;900&display=swap');

html {
  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;
}

body {
  font-family: 'Akshar', sans-serif;
  font-family: 'Megrim', cursive;
  font-family: 'Orbitron', sans-serif;
}

.checkwarranty {
  display: flex;
  height: 100vh;
  width: 100vw;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #142335;
}

.checkwarranty h1 {
  color: white;
  font-weight: 500;
  font-size: 3rem;
}

.checkwarranty__info {
  display: flex;
  flex-direction: column;
  gap: 1vh;
  background-color: transparent;
  width: 60%;
  height: 60%;
  justify-content: center;
  align-items: center;
  border-radius: 2rem;
}

.checkwarranty__form {
  display: flex;
  flex-direction: column;
  gap: 5vh;
  height: 80%;
  width: 60%;
  font-family: 'Obitron', sans-serif;
  /* justify-content: center; */
}

.checkbutton {
  background-color: #ECEFF2;
  color: #262626;
  width: 60%;
  align-self: center;
  height: 15%;
  border-style: none;
  font-family: 'Obitron', sans-serif;
  font-size: x-large;
}

.card-inner {
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 1s;
}

.card-inner.flipped {
  transform: rotateY(180deg);
  transition: transform 0.5s;
}

.card-front {
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #E4E4E4;
  border-radius: 2rem;
}

.card-back {
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #E4E4E4;
  border-radius: 2rem;
}

.card-back h1 {
  color: black;
  font-weight: 500;
  text-decoration: underline;
  text-decoration-color: green;
}

.card-back {
  transform: rotateY(180deg);
}

.error-message {
  color: red;
  font-weight: bold;
  margin-top: 10px;
}

.gradwrap {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
}

.grad {
  background-image: linear-gradient(180deg, rgb(140, 82, 255) 0%, rgb(53, 96, 194) 43.75%, rgb(94, 22, 235) 92.19%);
  height: 100%;
  width: 20%;
  border-radius: 2rem 0rem 0rem 2rem;
}

.proddet {
  margin-left: 2vh;
}

.ser,
.phn {
  height: 10vh;
}