@import url('https://fonts.googleapis.com/css2?family=Akshar:wght@300;400;500;600;700&family=Megrim&family=Orbitron:wght@400;500;600;700;800;900&family=Inter+Tight:wght@300;400;500&display=swap');

html{
  scroll-behavior: smooth;
    
} 

.shadow-custom1 {
  box-shadow: 0 70px 10px 0 rgba(0, 0, 0, 0.363);
}
.shadow-custom {
  

  box-shadow: 0  70px 15px 0 rgba(0, 0, 0, 0.514) ;
     
}

@media (min-width: 768px) {
  .shadow-custom {
  

    box-shadow: 0  120px 15px 0 rgba(0, 0, 0, 0.514) ;
       
  }
}




.color{
   background-color: rgba(0, 0, 0, 0.363) ;
     border-radius: 22px;
    
}

.color1{
  background-color: rgba(0, 0, 0, 0.363) ;
    border-radius: 30px;
   
}

body {
  font-family: 'Akshar', sans-serif, 'Megrim', cursive, 'Orbitron', sans-serif, 'Inter';
  
}

.scroll-snap-y {
  scroll-snap-type: y mandatory;
}

.scroll-snap-align {
  scroll-snap-align: start;
}

.rectangle-1 {
  background:linear-gradient(100deg, rgba(45, 119, 210, 0.411) 50%,rgba(48, 115, 196, 0.247) 50%);

}
    
.tex {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  background:linear-gradient(150deg, rgba(64, 128, 201, 0.589) 70%,rgba(48, 115, 196, 0.247) 30%);
  background-clip: text;
  font-size: 30vw;
  transform: translate(0%, -40%);
}
@media only screen and (min-width: 667px) and (max-width: 736px) {
  .tex {
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent;
    background: linear-gradient(150deg, rgba(64, 128, 201, 0.589) 70%, rgba(48, 115, 196, 0.247) 30%);
    background-clip: text;
    font-size: 90px; /* Adjust the font size for this range */
    transform: translate(0%, -50%);
  }
 
}
@media (min-width: 768px) {
 .tex {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  background:linear-gradient(150deg, rgba(64, 128, 201, 0.589) 70%,rgba(48, 115, 196, 0.247) 30%);
  background-clip: text;
  font-size: 30vw;
  transform: translate(0%, -80%);
}

}

@media (min-width: 1024px) {
  .tex {
   -webkit-background-clip: text !important;
   -webkit-text-fill-color: transparent;
   background:linear-gradient(150deg, rgba(64, 128, 201, 0.589) 70%,rgba(48, 115, 196, 0.247) 30%);
   background-clip: text;
   font-size: 30vw;
   transform: translate(0%, -10%);
 }
 .shadow-custom1 {
  box-shadow: 0 95px 10px 0 rgba(0, 0, 0, 0.363);
}
 }
 
.lower {



  background:linear-gradient(140deg, rgba(14, 141, 232, 0.342) 50%,rgba(25, 86, 160, 0.377)50%);
  z-index: 5;

}

.rectangle-5 {
  background: linear-gradient(180deg, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%);

}

.imm2{
 
  background-image: linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));

}



.element {
  position: relative;
  /* overflow: hidden; */
}

.element h1 {
  margin: 0;
  position: relative;
  z-index: 2;
  transition: transform 0.7s ease-in-out;
}

.element:hover h1 {
  transform: translateY(-18px);
}

.element img {
  position: relative;
  z-index: 1;

  transition: transform 0.5s ease-in-out;
}

.element:hover img {
  transform: scale(1.038);
  position: relative;
  bottom: 5px;
}

.scroll-animation {

  background-color: #f0f0f0;
  padding: 20px;
  margin: 200px 0;
}




::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-thumb {
  background-color: #1e1e1e85;
  border-radius: 6px;
}

::-webkit-scrollbar-track {
  background-color: #a49a9ace;
}